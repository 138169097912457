import {useTranslations} from '@/components/shared/Page/I18N';
import SocialIcons from '@/components/shared/SocialIcons/SocialIcons';
import {getSocialLinks} from '@/components/brochureV2/section/Footer/utils';
import RegionSelector from '@/components/brochureV2/section/Footer/RegionSelector/RegionSelector';
import {AlignmentEnum} from '@/enums';

import {getFooterNav, getFooterTopBarLinks} from './utils';

export const PartnersFooter = () => {
  const translations = useTranslations();
  const {t, localizePath} = translations;
  const topBar = getFooterTopBarLinks(translations);
  const footerNav = getFooterNav(translations);

  return (
    <div className="bg-black text-white">
      <div className="container">
        <div className="border-b border-[#151516] flex flex-col items-start md:flex-row pt-14 pb-4 md:pb-6">
          {topBar.map((topLinksItem) => (
            <a
              key={`key-${topLinksItem.text}`}
              href={topLinksItem.href}
              className="text-base font-bold mb-4 md:mr-5"
            >
              {topLinksItem.text}
            </a>
          ))}
        </div>
      </div>
      <div className="border-b border-[#151516] pt-8 md:pt-5">
        <div className="container flex flex-row">
          {footerNav.map((footerNavItem, index) => (
            <div
              key={index}
              className={`md:flex md:flex-row md:mb-6 ${footerNavItem.size}`}
            >
              {footerNavItem.items.map((linksItem) => (
                <div
                  key={`key-${linksItem.title}`}
                  className={`mb-16 md:mb-0 ${linksItem.size}`}
                >
                  <div className="text-sm uppercase font-extrabold mb-4">
                    {linksItem.title}
                  </div>
                  <div className={linksItem.cols}>
                    {linksItem.navItems.map((navItem) => (
                      <div key={`key-${navItem.text}`} className="mb-3 pr-4">
                        <a
                          className="text-sm text-[#B6BABF] hover:text-white"
                          href={navItem.href}
                        >
                          {navItem.text}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="container flex flex-col-reverse md:flex-row items-center justify-between pt-8 pb-16 md:py-10">
        <SocialIcons
          socialLinksFn={getSocialLinks}
          listClass="flex gap-4 md:gap-6 mt-5 md:mt-0"
          linkClass="block h-8 w-8 fill-white hover:fill-shade-30"
        />
        <div className="flex flex-col md:flex-row items-center">
          <a
            href={localizePath('https://www.shopify.com/legal/terms')}
            className="text-white text-15 ml-0 md:ml-6 mb-3 md:mb-[2px]"
          >
            {t('footer:termsOfService')}
          </a>
          <a
            href={localizePath('https://www.shopify.com/legal/privacy')}
            className="text-white text-15 ml-0 md:ml-6 mb-[2px]"
          >
            {t('footer:privacyPolicy')}
          </a>
          <RegionSelector alignment={AlignmentEnum.Right} />
        </div>
      </div>
    </div>
  );
};
