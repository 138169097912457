import type {I18NOptions} from '@/types';
import {
  aboutPath,
  businessNameGeneratorPath,
  careersPath,
  getForumsUrl,
  hardwareUrl,
  helpUrl,
  logoMakerPath,
  merchantSupportUrl,
  partnersPath,
  pressUrl,
  themesUrl,
} from '@/hooks/navigation/utils';

const onlineStore = ({t, localizePath, site}: I18NOptions) => [
  {
    text: t('footer:sellOnline'),
    href: localizePath('/online'),
  },
  {
    text: t('footer:examples'),
    href: localizePath('/examples'),
  },
  {
    text: t('footer:featureTour'),
    href: localizePath('/website/builder'),
  },
  {
    text: t('footer:sellOnlineTour'),
    href: localizePath('/tour'),
  },
  {
    text: t('footer:onlineStore'),
    href: localizePath('/tour/ecommerce-website'),
  },
  {
    text: t('footer:domainNames'),
    href: localizePath('/domains'),
  },
  {
    text: t('footer:shoppingCart'),
    href: localizePath('/tour/shopping-cart'),
  },
  {
    text: t('footer:ecommerceHosting'),
    href: localizePath('/tour/ecommerce-hosting'),
  },
  {
    text: t('footer:mobileCommerce'),
    href: localizePath('/mobile'),
  },
  {
    text: t('footer:onlineStoreBuilder'),
    href: localizePath('/online-store'),
  },
  {
    text: t('footer:dropshipping'),
    href: localizePath('/dropshipping'),
  },
  {
    text: t('footer:storeThemes'),
    href: themesUrl(site),
  },
];

const pointOfSale = ({t, localizePath, site}: I18NOptions) => [
  {
    text: t('footer:pointOfSale'),
    href: localizePath('/pos'),
  },
  {
    text: t('footer:posFeatures'),
    href: localizePath('/pos/features'),
  },
  {
    text: t('footer:hardware'),
    href: hardwareUrl(site),
  },
];

const support = ({t, localizePath, site}: I18NOptions) => [
  {
    text: t('footer:support'),
    href: merchantSupportUrl(site),
  },
  {
    text: t('footer:documentation'),
    href: helpUrl(site),
  },
  {
    text: t('footer:forums'),
    href: `${getForumsUrl(
      site.languageCode,
    )}?utm_campaign=footer&utm_content=en&utm_medium=web&utm_source=shopify`,
  },
  {
    text: t('footer:apiDocs'),
    href: 'https://shopify.dev/docs/api',
  },
  {
    text: t('footer:tools'),
    href: localizePath('/tools'),
  },
  {
    text: t('footer:burst'),
    href: localizePath('/stock-photos'),
  },
  {
    text: t('footer:logoGenerator'),
    href: logoMakerPath(site),
  },
  {
    text: t('footer:businessNameGenerator'),
    href: businessNameGeneratorPath(site),
  },
  {
    text: t('footer:research'),
    href: localizePath('/plus/commerce-trends'),
  },
  {
    text: t('footer:legal'),
    href: localizePath('/legal'),
  },
];

const shopify = ({t, localizePath, site}: I18NOptions) => [
  {
    text: t('footer:contact'),
    href: localizePath('/contact'),
  },
  {
    text: t('footer:partnerProgram'),
    href: partnersPath(site),
  },
  {
    text: t('footer:affiliateProgram'),
    href: localizePath('/affiliates'),
  },
  {
    text: t('footer:appDeveloperProgram'),
    href: 'https://shopify.dev/?shpxid=a8997906-5385-4535-23F8-D9928E4EE397',
  },
  {
    text: t('footer:investors'),
    href: 'https://investors.shopify.com/Home/default.aspx',
  },
  {
    text: t('footer:blogTopics'),
    href: localizePath('/blog/topics'),
  },
  {
    text: t('footer:communityEvents'),
    href: 'https://community.shopify.com/c/events/eb-p/events_en/home?utm_campaign=homepage&utm_content=en&utm_medium=web&utm_source=shopify',
  },
  {
    text: t('footer:fulfillment'),
    href: localizePath('/fulfillment'),
  },
];

export const getFooterNav = (options: I18NOptions) => [
  {
    size: 'w-1/2 md:w-4/6',
    items: [
      {
        size: 'md:w-3/4',
        cols: 'md:columns-3',
        title: options.t('footer:ecommerce'),
        navItems: onlineStore(options),
      },
      {
        size: 'md:w-1/4',
        cols: 'columns-1',
        title: options.t('footer:pointOfSale'),
        navItems: pointOfSale(options),
      },
    ],
  },
  {
    size: 'w-1/2 md:w-2/6',
    items: [
      {
        size: 'md:w-1/2',
        cols: 'columns-1',
        title: options.t('footer:resources'),
        navItems: support(options),
      },
      {
        size: 'md:w-1/2',
        cols: 'columns-1',
        title: options.t('footer:shopify'),
        navItems: shopify(options),
      },
    ],
  },
];

export const getFooterTopBarLinks = (options: I18NOptions) => {
  const {t, localizePath} = options;
  return [
    {
      text: t('footer:about'),
      href: aboutPath(options),
    },
    {
      text: t('footer:careers'),
      href: careersPath(options),
    },
    {
      text: t('footer:pressAndMedia'),
      href: pressUrl(options),
    },
    {
      text: t('footer:shopifyPlus'),
      href: localizePath('/plus'),
    },
    {
      text: t('footer:sitemap'),
      href: localizePath('/sitemap'),
    },
  ];
};
